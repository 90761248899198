import React from 'react';
import AddressForm from '../Forms/AddressForm';
import Loading from '../Loading/Loading';
import Button from '../Button';

export default function AddressNewModal(props) {
	const {
		setNewAddressFormOpen,
		setShowEdit,
		updateLoading,
		address,
		setUpdateLoading,
		postNewAddress,
		handleAddressInput,
	} = props || {};
	return (
		<div className='mainModal'>
			<AddressForm address={address} handleAddressInput={handleAddressInput} required={true} />
			{!updateLoading ? (
				<div className='btnCont modal reverse'>
					<Button
						text='Create address'
						style='primary'
						type='lg'
						onClick={() => {
							postNewAddress(address);
						}}
					/>
					<Button
						text='Cancel'
						style='secondary'
						type='lg'
						onClick={() => {
							setNewAddressFormOpen(false);
							setShowEdit(false);
						}}
					/>
				</div>
			) : (
				<>
					<Loading type='circle' size='small' />
				</>
			)}
		</div>
	);
}
