import Axios from 'axios';
import React, { useContext, useRef } from 'react';
import config from '../../config';
import AddressForm from '../../components/Forms/AddressForm';
import { v4 as uuidv4 } from 'uuid';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { useState } from 'react';
import Overlay from '../Overlay';
import AddressEditModal from '../../components/Modals/AddressEditModal';
import AddressNewModal from '../../components/Modals/AddressNewModal';
import Loading from '../../components/Loading/Loading';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import FadeOutModal from '../../components/Modals/FadeOutModal';
import Button from '../../components/Button';
import { ModalContext } from '../../contexts/ModalContext';

export default function Addresses({
	address,
	setAddress,
	addresses,
	chosenAddress,
	setChosenAddress,
	handleAddressInput,
	scrollToBottom,
	layoutType,
}) {
	const [modalOpen, setModalOpen] = useState({});
	const [errorMessage, setErrorMessage] = useState('');
	const [loadOverlay, setLoadOverlay] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [showAddressOptions, setShowAddressOptions] = useState(false);
	const [newAddressFormOpen, setNewAddressFormOpen] = useState(false);
	const [updatedAddress, setUpdatedAddress] = useState(false);
	const [updateLoading, setUpdateLoading] = useState(false);

	let { teamState, teamDispatch } = useContext(CurrentTeamContext);

	let { flashFadeModal } = useContext(ModalContext);

	let ref = useRef(null);

	const handleAddressUpdateInput = (value, name) => {
		if (value?.value) {
			value = value?.value;
		}
		setUpdatedAddress((address) => ({
			...address,
			[name]: value,
		}));
	};

	function handleModal(name, value) {
		setModalOpen((modalOpen) => ({
			...modalOpen,
			[name]: value,
		}));
	}

	function postNewAddress() {
		try {
			if (!address) {
				return;
			}
			let newAddress = {
				addressUuid: uuidv4(),
				isDefault: false,
				addressType: 'deliveryAddress',
				name: address.name,
				mobile: address.mobile,
				addressLine1: address.addressLine1,
				addressLine2: address.addressLine2,
				addressLine3: address.addressLine3,
				city: address.city,
				postalCode: address.postalCode,
				county: address.county,
				country: address.country,
				collectionHours: address.collectionHours,
				collectionPoint: address.collectionPoint,
				instructions: address.instructions,
			};

			if (
				!address.name ||
				!address.addressLine1 ||
				!address.city ||
				!address.postalCode ||
				!address.county ||
				!address.country
			) {
				flashFadeModal('Please fill out all required address fields');
				return;
			}

			setUpdateLoading(true);

			Axios({
				url: `${config.apiv1}/team/team.update`,
				method: 'PATCH',
				data: {
					teamUuid: teamState.currentTeam.teamUuid,
					deliveryAddress: newAddress,
				},
			}).then((res) => {
				if (res?.data?.error) {
					setErrorMessage('Error when creating team');
				} else {
					if (res?.data?.ok) {
						teamDispatch({ type: 'SET_TEAM_ADDRESSES', payload: res?.data?.data?.teamInfo?.addresses });
						setChosenAddress(newAddress?.addressUuid);
						setAddress({});
						setNewAddressFormOpen(false);
						setShowAddressOptions(false);
						setShowEdit(false);
						setLoadOverlay(false);
						handleFadeOutModal({
							show: true,
							isFading: false,
							message: 'Address successfully created',
						});
					} else {
						flashFadeModal('There was an error when creating the address');
					}
					setUpdateLoading(false);
				}
			});
		} catch (err) {
			setUpdateLoading(false);
			// if (err.response.status === 401) {
			// 	window.location.reload();
			// }
		}
	}

	function patchAddress(updatedAddress, param, addressUuid) {
		try {
			setUpdateLoading(true);
			let reqData;
			if (param === 'default') {
				reqData = {
					teamUuid: teamState.currentTeam.teamUuid,
					updatedAddress: { isDefault: true },
				};
			} else {
				reqData = {
					teamUuid: teamState.currentTeam.teamUuid,
					updatedAddress: updatedAddress,
				};
			}
			Axios({
				url: `${config.apiv1}/team/team.update/address/${addressUuid || updatedAddress.addressUuid}`,
				method: 'PATCH',
				data: reqData,
			}).then((res) => {
				if (res?.data?.ok) {
					teamDispatch({ type: 'SET_TEAM_ADDRESSES', payload: res?.data?.data?.teamInfo?.addresses });
					setChosenAddress(updatedAddress?.addressUuid);
					setNewAddressFormOpen(false);
					setShowAddressOptions(false);
					setShowEdit(false);
					setLoadOverlay(false);
					flashFadeModal('Address successfully updated');
				} else {
					handleFadeOutModal({
						show: true,
						isFading: false,
						message: 'There was an error when updating the address',
					});
				}
				setUpdateLoading(false);
			});
		} catch (err) {
			// if (err.response.status === 401) {
			// 	window.location.reload();
			// }
		}
	}

	function deleteAddress(addressUuid) {
		try {
			Axios({
				url: `${config.apiv1}/team/team.delete/address/${addressUuid}`,
				method: 'PATCH',
				data: {
					teamUuid: teamState.currentTeam.teamUuid,
				},
			}).then((res) => {
				if (res.data?.error) {
					setErrorMessage('Error when deleting address');
				} else {
					if (res?.data?.ok) {
						teamDispatch({ type: 'SET_TEAM_ADDRESSES', payload: res?.data?.data?.teamInfo?.addresses });
						setNewAddressFormOpen(false);
						setShowAddressOptions(false);
						setShowEdit(false);
						setLoadOverlay(false);
						handleModal('deleteModal', {
							open: false,
						});
						handleFadeOutModal({
							show: true,
							isFading: false,
							message: 'Address successfully deleted',
						});
					} else {
						handleFadeOutModal({
							show: true,
							isFading: false,
							message: 'There was an error when deleting the address',
						});
					}
					setUpdateLoading(false);
				}
			});
		} catch (err) {
			// if (err.response.status === 401) {
			// 	window.location.reload();
			// }
		}
	}

	function scroll() {
		ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
	}

	function handleFadeOutModal(state) {
		const second = 1000;
		setModalOpen((modalOpen) => ({
			...modalOpen,
			['modalFading']: state,
		}));
		setTimeout(() => {
			setModalOpen((modalOpen) => ({
				...modalOpen,
				['modalFading']: { show: true, isFading: true, message: state.message },
			}));
		}, second * 3);
	}

	return (
		<div className='addressesContainer'>
			<FadeOutModal
				show={modalOpen['modalFading']?.show}
				isFading={modalOpen['modalFading']?.isFading}
				message={modalOpen['modalFading']?.message}
			/>
			<Overlay loadOverlay={loadOverlay} showOverlay={showEdit || newAddressFormOpen} zIndex={96} />
			{modalOpen['deleteModal']?.open && (
				<>
					<ConfirmModal
						value={modalOpen['deleteModal'].value}
						header={modalOpen['deleteModal'].header}
						callback={modalOpen['deleteModal'].callback}
						handleModal={handleModal}
						modalType={'deleteModal'}
						loading={updateLoading}
						zIndex={999}
					/>
				</>
			)}
			{errorMessage && <h3 className='error'>{errorMessage}</h3>}
			{layoutType === 'addressPicker' ? (
				<>
					{!newAddressFormOpen && !showEdit ? (
						<>
							{showAddressOptions && !showEdit ? (
								<>
									<>
										{addresses?.length !== 0 ? (
											<>
												{addresses &&
													Object.values(addresses)?.map((address, key) => {
														if (
															address &&
															address.name &&
															address.addressLine1 &&
															address.city &&
															address.postalCode &&
															address.country
														) {
															return (
																<div className='formRowTop' key={key}>
																	<label className='radioLabel'>
																		<input
																			type='radio'
																			name='deliveryAddress'
																			checked={
																				chosenAddress == address?.addressUuid
																			}
																			onChange={() => {
																				setChosenAddress(address?.addressUuid);
																			}}
																		/>
																		<div className='customRadio'></div>
																		<span>
																			{`${address?.name}, 
																	${address?.addressLine1}, 
																	${address?.city}, 
																	${address?.postalCode}, 
																	${address?.country} 
																	${address?.addressType === 'registeredAddress' ? '(Registered address)' : '(Delivery address)'}`}
																		</span>
																	</label>
																	<div className='addressEditBtn'>
																		<button
																			className='functionalButtonSecondary'
																			onClick={() => {
																				setShowEdit(true);
																				setUpdatedAddress(address);
																			}}>
																			Edit
																		</button>
																	</div>
																</div>
															);
														} else {
															return <React.Fragment key={key}></React.Fragment>;
														}
													})}
											</>
										) : (
											<>No addresses</>
										)}
										{showEdit && (
											<div className='editAddressBox'>
												<AddressForm
													address={address}
													handleAddressInput={handleAddressInput}
													required={false}
												/>
											</div>
										)}
									</>
									{addresses && Object.keys(addresses)?.length > 0 && (
										<div>
											<input
												type='button'
												className='functionalButtonPrimary'
												onClick={() => {
													setShowAddressOptions(false);
												}}
												defaultValue='Select'
											/>
										</div>
									)}
									<div className='marginTop-10'>
										<input
											type='button'
											className='functionalButtonSecondary'
											onClick={() => {
												setNewAddressFormOpen(true);
											}}
											defaultValue='Add new'
										/>
									</div>
								</>
							) : (
								<>
									{addresses && chosenAddress && addresses[chosenAddress]?.addressLine1 ? (
										<div className='addressDisplay'>
											{`${
												addresses[chosenAddress]?.name
													? `${addresses[chosenAddress]?.name}, `
													: ''
											}${
												addresses[chosenAddress]?.addressLine1
													? `${addresses[chosenAddress]?.addressLine1}, `
													: ''
											} ${
												addresses[chosenAddress]?.city
													? `${addresses[chosenAddress]?.city}, `
													: ''
											} ${
												addresses[chosenAddress]?.postalCode
													? `${addresses[chosenAddress]?.postalCode},`
													: ''
											} ${
												addresses[chosenAddress]?.country
													? `${addresses[chosenAddress]?.country}`
													: ''
											}`}
										</div>
									) : (
										<div className='addressDisplay'>No address chosen</div>
									)}
									<input
										type='button'
										className='functionalButtonSecondary'
										onClick={() => {
											setShowAddressOptions(true);
											scroll();
										}}
										defaultValue='Change'
									/>
								</>
							)}
						</>
					) : (
						<>
							{!showEdit ? (
								<>
									<AddressForm
										handleAddressInput={handleAddressInput}
										address={address}
										required={false}
									/>
									<div className='marginTop-10'>
										<input
											type='button'
											className='functionalButtonPrimary'
											onClick={() => {
												postNewAddress();
											}}
											defaultValue='Save new address'
										/>
									</div>
									<div className='marginTop-10'>
										<input
											type='button'
											className='functionalButtonSecondary'
											onClick={() => {
												setNewAddressFormOpen(false);
												setShowEdit(false);
											}}
											defaultValue='Cancel'
										/>
									</div>
								</>
							) : (
								<>
									<AddressForm
										address={updatedAddress}
										handleAddressInput={handleAddressUpdateInput}
										required={false}
									/>
									<div className='marginTop-10'>
										<input
											type='button'
											className='functionalButtonPrimary'
											onClick={() => {
												patchAddress(updatedAddress);
											}}
											defaultValue='Update address'
										/>
									</div>
									<div className='marginTop-10'>
										<input
											type='button'
											className='functionalButtonSecondary'
											onClick={() => {
												setNewAddressFormOpen(false);
												setShowEdit(false);
											}}
											defaultValue='Cancel'
										/>
									</div>
								</>
							)}
						</>
					)}
				</>
			) : (
				<>
					<div className='addressManagementContainer'>
						{showEdit && (
							<AddressEditModal
								handleAddressUpdateInput={handleAddressUpdateInput}
								patchAddress={patchAddress}
								setNewAddressFormOpen={setNewAddressFormOpen}
								setShowEdit={setShowEdit}
								setUpdateLoading={setUpdateLoading}
								updateLoading={updateLoading}
								updatedAddress={updatedAddress}
							/>
						)}
						{newAddressFormOpen && (
							<AddressNewModal
								postNewAddress={postNewAddress}
								setNewAddressFormOpen={setNewAddressFormOpen}
								setShowEdit={setShowEdit}
								setUpdateLoading={setUpdateLoading}
								handleAddressInput={handleAddressInput}
								updateLoading={updateLoading}
								address={address}
							/>
						)}
						{addresses?.map((address, key) => {
							return (
								<div className='addressItem' key={key}>
									<div className='leftCol'>
										<h4>{address?.name}</h4>
										<h4>{address?.addressLine1}</h4>
										<h4>{address?.city}</h4>
										<h4>{address?.postalCode}</h4>
										<h4>{address?.country}</h4>
									</div>
									<div className='addressButtons'>
										<Button
											text='Edit'
											size='sm'
											onClick={() => {
												setShowEdit(true);
												setLoadOverlay(true);
												setUpdatedAddress(address);
											}}
										/>
										{address && address?.addressType !== 'registeredAddress' && (
											<>
												<Button
													text='Delete'
													size='sm'
													onClick={() => {
														handleModal('deleteModal', {
															open: true,
															value: address?.addressUuid,
															callback: deleteAddress,
															header: 'Are you sure you wish to delete this address?',
														});
													}}
												/>
											</>
										)}
										{address && !address.isDefault ? (
											<Button
												text='Make default'
												size='sm'
												onClick={() => {
													patchAddress(null, 'default', address?.addressUuid);
												}}
											/>
										) : (
											<span style={{ color: 'var(--white)' }}>(Default)</span>
										)}
									</div>
								</div>
							);
						})}
						<div
							className='addAddress'
							onClick={() => {
								setNewAddressFormOpen(true);
								setLoadOverlay(true);
							}}>
							<h3>Add address</h3>
						</div>
					</div>
				</>
			)}
		</div>
	);
}
