import Axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ReactComponent as UploadCloudWhiteSVG } from '../../assets/svg/upload-cloud-white.svg';
import Loading from '../../components/Loading/Loading';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import FadeOutModal from '../../components/Modals/FadeOutModal';
import ListingPagination from '../../components/Pagination/ListingPagination';
import {
	ArrowLeftIcon,
	ArrowNarrowLeftIcon,
	DownloadCloudIcon,
	File2LinesIcon,
	FileIcon,
	FileThinIcon,
	PlusIcon,
	SearchIcon,
	TrashIcon,
	UploadCloudIcon,
} from '../../components/SVGIcons/SVGIcons';
import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { checkFileType, checkFilenameLength, dateConverter, parseFileSize } from '../../helpers';
import useFetch from '../../hooks/useFetch';
import SelectCustom from '../../components/Select/SelectCustom';
import UploadModal from '../../components/Modals/UploadModal';
import Overlay from '../../components/Overlay';
import EmptyState from '../../components/EmptyState/EmptyState';
import { useLocation, useNavigate } from 'react-router';
import Button from '../../components/Button';
import { v4 as uuidv4 } from 'uuid';

export default function TeamFiles() {
	let navigate = useNavigate();
	let location = useLocation();
	let searchParam = new URLSearchParams(location.search).get('search');
	let sortParam = new URLSearchParams(location.search).get('sort');
	let returnUri = new URLSearchParams(location.search).get('returnUri');

	const [loaded, setLoaded] = useState(false);
	const [loading, setLoading] = useState(false);
	const [files, setFiles] = useState({});
	const [modalOpen, setModalOpen] = useState({});
	const [fileLocation, setFileLocation] = useState(1);
	const [uploadProgress, setUploadProgress] = useState({});
	const [fileSearch, setFileSearch] = useState(searchParam || '');

	const [uploading, setUploading] = useState(false);
	const [newFiles, setNewFiles] = useState([]);
	const [completedFiles, setCompletedFiles] = useState([]);
	const [sort, setSort] = useState(sortParam || 'date.desc');
	const [pages, setPages] = useState(0);

	const maxSize = 2000 * 1000 * 1000;
	const CHUNK_SIZE_OLD = 2 * 1024 * 1024;
	const CHUNK_SIZE = 100 * 1000;

	const { teamState } = useContext(CurrentTeamContext);

	const { data, fullData, reqLoading, fetch } = useFetch(
		`${config.apiv1}/file/files.read/${teamState.currentTeam.teamUuid}?${fileSearch ? `search=${fileSearch}` : ''}`
	);

	const updateDebounceSearch = useCallback(
		debounce((searchValue) => {
			setFileSearch(searchValue);
			setLoading(false);
		}),
		[]
	);

	function debounce(cb, delay = 400) {
		let timeout;
		return (...args) => {
			setLoading(true);
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				cb(...args);
			}, delay);
		};
	}

	function handlePagination(data) {
		setLoaded(false);
		const gridItemLimit = 15;
		let selected = data.selected;
		let newSkip = Math.ceil(selected * gridItemLimit);
		fetch(
			`${config.apiv1}/file/files.read/${teamState.currentTeam.teamUuid}?skip=${newSkip}&sort=${sort}${
				fileSearch ? `search=${fileSearch}` : ''
			}`
		);
	}

	async function createImgUrls(filesArr) {
		if (filesArr?.length > 0) {
			let parsedFiles = [];
			for (let i = 0; i < filesArr.length; i++) {
				if (checkFileType(filesArr[i]?.filename?.split('.')[1]) === 1) {
					if (filesArr[i].fileAttachmentType !== 2) {
						try {
							const res = await Axios({
								url: `${config.api}/file?path=${filesArr[i].path}`,
								method: 'GET',
								responseType: 'blob',
							});

							if (res.data) {
								const url = window.URL.createObjectURL(new Blob([res.data]));
								let file = filesArr[i];
								file.url = url;
								parsedFiles.push(file);
							}
						} catch (err) {
							console.log(err);
						}
					}
				} else if (checkFileType(filesArr[i]?.filename?.split('.')[1]) === 2) {
					parsedFiles.push(filesArr[i]);
				}
			}
			setFiles(parsedFiles);
		} else {
			setFiles([]);
		}
		setLoaded(true);
	}

	const handleNewFiles = (files, name) => {
		if (!files?.length) {
			files = [files];
		}
		setNewFiles(() => [...newFiles, ...files]);
	};

	function handleFadeOutModal(state) {
		const second = 1000;
		setModalOpen((modalOpen) => ({
			...modalOpen,
			['modalFading']: state,
		}));
		setTimeout(() => {
			setModalOpen((modalOpen) => ({
				...modalOpen,
				['modalFading']: { show: true, isFading: true, message: state.message },
			}));
		}, second * 3);
	}

	const updateQueryParams = () => {
		const params = new URLSearchParams();
		if (fileSearch) params.set('search', fileSearch);
		if (sort) params.set('sort', sort);

		navigate({ search: params.toString() });
	};

	function handleSort(value) {
		setSort(value);
		fetch(`${config.apiv1}/file/files.read/${teamState.currentTeam.teamUuid}?sort=${value}&search=${fileSearch}`);
		updateQueryParams();
	}

	function handleModal(name, value) {
		setModalOpen((modalOpen) => ({
			...modalOpen,
			[name]: value,
		}));
	}

	function handleFileLocation(data) {
		setFileLocation(() => data.value);
	}

	function confirmVoid(fileUuid) {
		handleModal('voidModal', {
			open: true,
			header: 'Are you sure you want to delete this file?',
			callback: () => {
				voidFile(fileUuid);
				handleModal('voidModal', false);
			},
		});
	}

	async function voidFile(fileUuid) {
		let res = await Axios({
			url: `${config.apiv1}/file/file.void`,
			method: 'PATCH',
			data: {
				fileUuid: fileUuid,
			},
		});
		if (res.data.ok) {
			let filtered = [...files].filter((file) => file.fileUuid !== fileUuid);
			setFiles(() => filtered);
			handleFadeOutModal({
				show: true,
				isFading: false,
				message: 'File successfully deleted',
			});
		}
	}

	const uploadChunk = async (chunk, index, filename, chunksDirUuid) => {
		const formData = new FormData();
		formData.append('teamUuid', teamState.currentTeam.teamUuid);
		formData.append('chunkIndex', index);
		formData.append('filename', filename);
		formData.append('chunksDirUuid', chunksDirUuid);
		formData.append('chunk', chunk);

		await Axios.post(`${config.apiv1}/file/chunk.create`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
	};

	async function postFiles() {
		setUploading(true);
		for (let i = 0; i < newFiles?.length; i++) {
			if (!newFiles[i]) return;

			const chunksDirUuid = uuidv4();
			const file = newFiles[i];
			const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
			const filename = file.name?.split('.')[0];
			const mimetype = file.name?.split('.')[1];
			let uploadedChunks = 0;

			for (let i = 0; i < totalChunks; i++) {
				const start = i * CHUNK_SIZE;
				const end = Math.min(start + CHUNK_SIZE, file.size);
				const chunk = file.slice(start, end);

				await uploadChunk(chunk, i, filename, chunksDirUuid);
				uploadedChunks += 1;
				let progressPercent = Math.round((uploadedChunks / totalChunks) * 100);
				setUploadProgress((files) => ({
					...files,
					[file.name]: {
						percent: progressPercent,
						complete: progressPercent >= 100,
					},
				}));
			}
			await Axios.post(`${config.apiv1}/file/chunks.merge`, {
				filename,
				totalChunks,
				chunksDirUuid,
				mimetype,
				fileAttachmentType: fileLocation,
				teamUuid: teamState.currentTeam.teamUuid,
				originalname: file.name,
				size: file.size,
			});

			// formData.append('files', newFiles[i]);
		}
		// formData.append('teamUuid', teamState.currentTeam.teamUuid);
		// formData.append('fileAttachmentType', fileLocation);
		// let res = await Axios({
		// 	url: `${config.apiv1}/file/file.create`,
		// 	method: 'POST',
		// 	data: formData,
		// });
		// if (res.data.ok) {
		setCompletedFiles((completedFiles) => [...newFiles, ...completedFiles]);
		fetch();
		setNewFiles([]);
		// handleModal('uploadModal', { open: false });
		setUploading(false);

		handleFadeOutModal({
			show: true,
			isFading: false,
			message: 'New file(s) successfully uploaded',
		});
		// }
	}

	async function removeFile(file) {
		let newArr = [...newFiles];
		let i = newArr.indexOf(file);
		newArr.splice(i, i + 1);
		setNewFiles(newArr);
	}

	function downloadFile(file) {
		Axios({
			url: `${config.api}/file?path=${file.path}`,
			method: 'GET',
			responseType: 'blob',
		}).then((response) => {
			const url = window.URL.createObjectURL(response.data);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', file.filename);
			document.body.appendChild(link);
			link.click();
		});
	}

	useEffect(() => {
		let mounted = true;
		if (mounted && reqLoading === false) {
			const onePage = 0;
			let sortedArr =
				data &&
				data?.sort((a, b) => {
					if (a.createdAt < b.createdAt) {
						return 1;
					}
					if (a.createdAt > b.createdAt) {
						return -1;
					}
					return 0;
				});
			if (!data) {
				setLoaded(true);
				setFiles([]);
				setPages(0);
				return;
			}
			setPages(fullData ? Math.ceil(fullData?.totalDocuments / 15) : onePage);
			createImgUrls(sortedArr);
		}
		return () => (mounted = false);
	}, [reqLoading, data, fullData]);

	useEffect(() => {
		if (newFiles?.length !== 0) {
			postFiles();
		}
	}, [newFiles]);

	useEffect(() => {
		updateQueryParams();
	}, [sort, fileSearch]);

	return (
		<div className='pageInnerCont white'>
			{modalOpen['voidModal']?.open && (
				<>
					<ConfirmModal
						value={modalOpen['voidModal'].value}
						header={modalOpen['voidModal'].header}
						callback={modalOpen['voidModal'].callback}
						handleModal={handleModal}
						modalType={'voidModal'}
						zIndex={999}
					/>
				</>
			)}
			{modalOpen['uploadModal']?.open && (
				<>
					<Overlay
						loadOverlay={true}
						showOverlay={true}
						setShowOverlay={() => {
							handleModal('uploadModal', { open: false });
							setCompletedFiles([]);
						}}
					/>
					<UploadModal
						fileLocationOptions={[
							<option value='1'>General</option>,
							<option value='3'>Shop downloads section</option>,
							<option value='4'>Shop gallery section</option>,
						]}
						handleFileLocation={handleFileLocation}
						uploadProgress={uploadProgress}
						handleNewFiles={handleNewFiles}
						completedFiles={completedFiles}
						fileLocation={fileLocation}
						handleModal={handleModal}
						removeFile={removeFile}
						maxFileSize={maxSize}
						postFiles={postFiles}
						uploading={uploading}
						files={newFiles}
					/>
				</>
			)}
			<FadeOutModal
				show={modalOpen['modalFading']?.show}
				isFading={modalOpen['modalFading']?.isFading}
				message={modalOpen['modalFading']?.message}
			/>
			{loaded && !reqLoading ? (
				<>
					<div className='teamHeader'>
						<div className='flexRow'>
							<File2LinesIcon iconClass='mainIcon' />
							<h2>File managment</h2>
						</div>
						<div className='flexRow'>
							<div>
								<input
									placeholder='Search...'
									className='textFieldInput'
									defaultValue={fileSearch}
									onChange={(e) => {
										updateDebounceSearch(e.target.value);
									}}
								/>
							</div>
							<SelectCustom
								htmlOptions={[
									<option value='date.desc'>Newest to oldest</option>,
									<option value='date.asc'>Oldest to newest</option>,
									<option value='name.asc'>Alphabetical A-Z</option>,
									<option value='name.desc'>Alphabetical Z-A</option>,
								]}
								callback={(e) => {
									handleSort(e.value);
								}}
								value={sort}
								text={'Sort by'}
								width={200}
							/>
							<Button
								size='md'
								text='Add file'
								style='primary'
								onClick={() => {
									handleModal('uploadModal', { open: true });
								}}
								iconLeft={<PlusIcon />}
							/>
							{returnUri ? (
								<Button
									onClick={() => navigate(decodeURI(returnUri))}
									size='md'
									style='secondary'
									iconLeft={<ArrowLeftIcon />}
									maxWidth={40}
									minWidth={40}
								/>
							) : (
								<></>
							)}
						</div>
					</div>
					{loading ? (
						<Loading type='facebook' />
					) : (
						<>
							{files && files.length > 0 ? (
								<div className='filesList'>
									{files.map((file, key) => {
										return (
											<div key={key} className='fileCont'>
												<div className='col'>
													{file.url ? (
														<img className='thumb' src={file.url} alt='thumbnail' />
													) : (
														<div className='thumbPlaceholder'>
															<File2LinesIcon />
														</div>
													)}
												</div>
												<div className='col'>
													<div className='btnCont'>
														<div
															className='iconCont'
															onClick={() => {
																confirmVoid(file.fileUuid);
															}}>
															<TrashIcon />
														</div>
														<div
															className='iconCont'
															onClick={() => {
																downloadFile(file);
															}}>
															<DownloadCloudIcon />
														</div>
													</div>
													<div className='textCont'>
														<p className='filename'>
															{checkFilenameLength(file.originalname, 15)}
														</p>
														<div className='textRow'>
															<p>Uploaded: </p>
															<p>
																<span className='spanBold'>
																	{dateConverter(file.createdAt)}
																</span>
															</p>
														</div>
														<div className='textRow'>
															<p>Size: </p>
															<p>
																<span className='spanBold'>
																	{parseFileSize(file.size)}
																</span>
															</p>
														</div>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							) : (
								<div className='pageInnerCont gray'>
									<EmptyState
										type='files'
										body='You have no files. Start by uploading files to ...'
										btn1={{
											text: 'Go Back',
											icon: <ArrowNarrowLeftIcon iconClass='emptyStateIcon' />,
											callback: () => {
												navigate(-1);
											},
										}}
										btn2={{
											text: 'Add files',
											icon: <UploadCloudWhiteSVG className='emptyStateIcon colorless' />,
											callback: () => {
												handleModal('uploadModal', { open: true });
											},
										}}
										header='No files found'
									/>
								</div>
							)}
						</>
					)}
				</>
			) : (
				<Loading type='circle' />
			)}
			{files && files.length > 0 && pages > 0 && (
				<ListingPagination pages={pages} handleClick={handlePagination} />
			)}
		</div>
	);
}
