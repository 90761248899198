import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import FileInput from '../../../components/Inputs/FileInput';
import TextareaInput from '../../../components/Inputs/TextareaInput';
import TextInput from '../../../components/Inputs/TextInput';
import { CloseIcon, FloppyIcon, PlusIcon, TopnavCloseIcon, TrashIcon } from '../../../components/SVGIcons/SVGIcons';
import { parseFieldIcon, parseToOptions } from '../../../helpers';
import { v4 as uuidv4 } from 'uuid';
import CheckboxInput from '../../../components/Inputs/CheckboxInput';
import SelectInput from '../../../components/Inputs/SelectInput';

export default function EditBox(props) {
	const {
		field,
		colId,
		index,
		close,
		remove,
		save,
		handleFormInput,
		formData,
		customErrors,
		addSelectField,
		handleSelectField,
		checkIfDupeBehaviorAndCorrect,
		popOption,
		handleFile,
		addRadio,
	} = props || {};

	const [selected, setSelected] = useState(1);
	const [selectedApp, setSelectedApp] = useState(null);

	const withTabs = ['select', 'paragraph', 'input.date', 'input.text', 'textarea'];

	const apps = {
		'137e5b3a-f164-474b-aa59-6b4ac97040f6': {
			name: 'Dentally',
			appUuid: '137e5b3a-f164-474b-aa59-6b4ac97040f6',
			fieldKeys: [
				'id',
				'title',
				'first_name',
				'middle_name',
				'last_name',
				'date_of_birth',
				'email_address',
				'nhs_number',
				'ni_number',
				'address_line_1',
				'address_line_2',
				'address_line_3',
				'town',
				'county',
				'postcode',
				'home_phone',
				'mobile_phone',
				'work_phone',
			],
		},
	};

	function checkFieldMap(fieldMap) {
		if (formData?.fieldMap !== null && formData?.fieldMap !== undefined && formData?.fieldMap !== 'undefined') {
			return `${formData?.fieldMap}{{${fieldMap}}}`;
		} else {
			return `{{${fieldMap}}}`;
		}
	}

	return (
		<Draggable draggableId={field.id} index={index}>
			{(provided) => (
				<div className='editBoxCont' {...provided.draggableProps} ref={provided.innerRef}>
					<div className='hidden' {...provided.dragHandleProps}></div>
					<div className='headerCont'>
						<div className='headerInner'>
							{parseFieldIcon(field.type)}
							<h4>{field.name}</h4>
							<div
								className='docketIcon save marginLeft-auto'
								onClick={() => {
									save();
								}}>
								<FloppyIcon />
							</div>
							<div className='docketIcon manage' onClick={() => remove(field?.id, colId)}>
								<TrashIcon />
							</div>
							<div className='docketIcon close' onClick={close}>
								<CloseIcon />
							</div>
						</div>
						{withTabs.includes(field.type) && (
							<>
								<p>{field.paragraph}</p>
								<div className='tabs edit'>
									<div
										className={`tab${selected === 1 ? ' selected' : ''}`}
										onClick={() => {
											setSelected(1);
										}}>
										<span>Edit</span>
									</div>
									<div
										className={`tab${selected === 2 ? ' selected' : ''}`}
										onClick={() => {
											setSelected(2);
										}}>
										<span>Behaviour</span>
									</div>
								</div>
							</>
						)}
					</div>
					<div className={field.type === 'select' ? 'editBody marginTop-20' : 'editBody'}>
						{(() => {
							switch (field.type) {
								case 'select':
									return (
										<>
											{selected === 1 && (
												<>
													<TextInput
														text={'Label'}
														name={'fieldText'}
														callback={handleFormInput}
														defaultValue={formData?.fieldText}
														customError={customErrors['fieldText']}
														required
													/>
													{formData?.selectOptions?.map((option, key) => {
														return (
															<TextInput
																text={option.text}
																name={option.name}
																callback={handleSelectField}
																defaultValue={option.value}
																key={key}
																iconLeft={
																	key !== 0 && (
																		<TopnavCloseIcon
																			iconClass='leftIcon'
																			onClick={popOption}
																		/>
																	)
																}
																customError={customErrors[option.name]}
																required
															/>
														);
													})}
													<div
														className='docketIcon discIcon plus marginTop-10'
														onClick={() => {
															addSelectField({
																name: `${uuidv4()}_${formData?.fieldText
																	?.replace(' ', '_')
																	.toLowerCase()}_${
																	formData?.selectOptions?.length + 1 || 1
																}`,
																value: null,
																text: `Option #${
																	formData?.selectOptions?.length + 1 || 1
																}`,
															});
														}}>
														<PlusIcon />
													</div>
												</>
											)}
											{selected === 2 && (
												<>
													<TextInput
														text={'Field map'}
														name={'fieldMap'}
														callback={handleFormInput}
														value={formData?.fieldMap}
														customError={customErrors?.fieldMap}
														tooltip={
															"This option allows you to auto-fill a custom text field with incoming data from your customers' installed integrations. You may choose more than one possible field, but only the first match will be applied to this field."
														}
														controlled
													/>
													<SelectInput
														options={parseToOptions(Object.values(apps), 'apps')}
														placeholder='Select...'
														text={'Apps'}
														callback={({ value }) => {
															setSelectedApp(value);
														}}
														value={formData?.selectedApp}
														name={'selectedApp'}
														tooltip={
															'When an option is selected, you will be presented with all fields from that specific integration that are able to be passed through to your Procuur custom form'
														}
													/>
													{selectedApp && (
														<div className='suggestionsCont'>
															{apps[selectedApp]?.fieldKeys?.map((fieldKey, key) => (
																<div
																	className='suggestion'
																	key={key}
																	onClick={() => {
																		handleFormInput(
																			checkFieldMap(fieldKey),
																			'fieldMap'
																		);
																	}}>
																	{fieldKey}
																</div>
															))}
														</div>
													)}
													<CheckboxInput
														text='Make field required?'
														name={'required'}
														checked={formData?.required === true}
														callback={handleFormInput}
													/>
												</>
											)}
										</>
									);
								case 'input.text':
									return (
										<>
											{selected === 1 && (
												<>
													<TextInput
														text={'Label'}
														name={'fieldText'}
														callback={handleFormInput}
														defaultValue={formData?.fieldText}
														customError={customErrors['fieldText']}
														required
													/>
													<TextInput
														text={'Placeholder'}
														name={'placeholder'}
														callback={handleFormInput}
														defaultValue={formData?.placeholder}
														customError={customErrors['placeholder']}
													/>
												</>
											)}
											{selected === 2 && (
												<>
													<TextInput
														text={'Field map'}
														name={'fieldMap'}
														callback={handleFormInput}
														value={formData?.fieldMap}
														customError={customErrors?.fieldMap}
														tooltip={
															"This option allows you to auto-fill a custom text field with incoming data from your customers' installed integrations. You may choose more than one possible field, but only the first match will be applied to this field."
														}
														controlled
													/>
													<SelectInput
														options={parseToOptions(Object.values(apps), 'apps')}
														placeholder='Select...'
														text={'Apps'}
														callback={({ value }) => {
															setSelectedApp(value);
														}}
														value={formData?.selectedApp}
														name={'selectedApp'}
														tooltip={
															'When an option is selected, you will be presented with all fields from that specific integration that are able to be passed through to your Procuur custom form'
														}
													/>
													{selectedApp && (
														<div className='suggestionsCont'>
															{apps[selectedApp]?.fieldKeys?.map((fieldKey, key) => (
																<div
																	className='suggestion'
																	key={key}
																	onClick={() => {
																		handleFormInput(
																			checkFieldMap(fieldKey),
																			'fieldMap'
																		);
																	}}>
																	{fieldKey}
																</div>
															))}
														</div>
													)}
													<CheckboxInput
														text='Make field required?'
														name={'required'}
														checked={formData?.required === true}
														callback={handleFormInput}
													/>
												</>
											)}
										</>
									);
								case 'input.phone':
									return (
										<>
											<TextInput
												text={'Label'}
												name={'fieldText'}
												callback={handleFormInput}
												defaultValue={formData?.fieldText}
												customError={customErrors['fieldText']}
												required
											/>
											<TextInput
												text={'Placeholder'}
												name={'placeholder'}
												callback={handleFormInput}
												defaultValue={formData?.placeholder}
												customError={customErrors['placeholder']}
											/>
											<CheckboxInput
												text='Make field required?'
												name={'required'}
												checked={formData?.required === true}
												callback={handleFormInput}
											/>
										</>
									);
								case 'textarea':
									return (
										<>
											{' '}
											{selected === 1 && (
												<>
													<TextInput
														text={'Label'}
														name={'fieldText'}
														callback={handleFormInput}
														defaultValue={formData?.fieldText}
														customError={customErrors['fieldText']}
														required
													/>
													<TextInput
														text={'Placeholder'}
														name={'placeholder'}
														callback={handleFormInput}
														defaultValue={formData?.placeholder}
														customError={customErrors['placeholder']}
													/>
													<CheckboxInput
														text='Make field required?'
														name={'required'}
														checked={formData?.required === true}
														callback={handleFormInput}
													/>
												</>
											)}
											{selected === 2 && (
												<>
													<TextInput
														text={'Field map'}
														name={'fieldMap'}
														callback={handleFormInput}
														value={formData?.fieldMap}
														customError={customErrors?.fieldMap}
														tooltip={
															"This option allows you to auto-fill a custom text field with incoming data from your customers' installed integrations. You may choose more than one possible field, but only the first match will be applied to this field."
														}
														controlled
													/>
													<SelectInput
														options={parseToOptions(Object.values(apps), 'apps')}
														placeholder='Select...'
														text={'Apps'}
														callback={({ value }) => {
															setSelectedApp(value);
														}}
														value={formData?.selectedApp}
														name={'selectedApp'}
														tooltip={
															'When an option is selected, you will be presented with all fields from that specific integration that are able to be passed through to your Procuur custom form'
														}
													/>
													{selectedApp && (
														<div className='suggestionsCont'>
															{apps[selectedApp]?.fieldKeys?.map((fieldKey, key) => (
																<div
																	className='suggestion'
																	key={key}
																	onClick={() => {
																		handleFormInput(
																			checkFieldMap(fieldKey),
																			'fieldMap'
																		);
																	}}>
																	{fieldKey}
																</div>
															))}
														</div>
													)}
													<CheckboxInput
														text='Make field required?'
														name={'required'}
														checked={formData?.required === true}
														callback={handleFormInput}
													/>
												</>
											)}
										</>
									);
								case 'input.date':
									return (
										<>
											{selected === 1 ? (
												<>
													<TextInput
														text={'Label'}
														name={'fieldText'}
														callback={handleFormInput}
														defaultValue={formData?.fieldText || formData?.fieldText}
														customError={customErrors['fieldText']}
														required
													/>
													<TextInput
														text={'Minimum days'}
														name={'minDays'}
														type='number'
														callback={handleFormInput}
														defaultValue={formData?.minDays}
														customError={customErrors['minDays']}
														required
													/>
												</>
											) : (
												<>
													<CheckboxInput
														text='Add clock time option?'
														name={'timeSelect'}
														checked={formData?.timeSelect === true}
														callback={handleFormInput}
													/>
													<CheckboxInput
														text='Is this date the "required by" date?'
														name={'requiredBy'}
														checked={formData?.requiredBy === true}
														callback={(value, name) => {
															checkIfDupeBehaviorAndCorrect('requiredBy', colId);
															handleFormInput(value, name);
														}}
													/>
													<CheckboxInput
														text='Make field required?'
														name={'required'}
														checked={formData?.required === true}
														callback={handleFormInput}
													/>
												</>
											)}
										</>
									);
								case 'input.file':
									return (
										<>
											<TextInput
												text={'Label'}
												name={'fieldText'}
												callback={handleFormInput}
												defaultValue={formData?.fieldText}
												customError={customErrors['fieldText']}
												required
											/>
											<CheckboxInput
												text='Make field required?'
												name={'required'}
												checked={formData?.required === true}
												callback={handleFormInput}
											/>
										</>
									);
								case 'input.checkbox':
									return (
										<>
											<TextInput
												text={'Label'}
												name={'fieldText'}
												callback={handleFormInput}
												defaultValue={formData?.fieldText}
												customError={customErrors['fieldText']}
												required
											/>
											<CheckboxInput
												text='Make field required?'
												name={'required'}
												checked={formData?.required === true}
												callback={handleFormInput}
											/>
										</>
									);
								case 'input.radio':
									return (
										<>
											<TextInput
												text={'Label'}
												name={'fieldText'}
												callback={handleFormInput}
												defaultValue={formData?.fieldText}
												customError={customErrors['fieldText']}
												required
											/>
											<TextInput
												text={'Radio choice #1'}
												name={'radio1'}
												callback={handleFormInput}
												defaultValue={formData?.radio1}
												customError={customErrors['radio1']}
												required
											/>
											<TextInput
												text={'Radio choice #2'}
												name={'radio2'}
												callback={handleFormInput}
												defaultValue={formData?.radio2}
												customError={customErrors['radio2']}
												required
											/>
											{typeof formData?.extraRadios === 'object' &&
												Object.keys(formData?.extraRadios)?.map((radio, key) => {
													return (
														<React.Fragment key={key}>
															<TextInput
																text={formData.extraRadios[radio].inputText}
																name={formData.extraRadios[radio].text}
																callback={handleFormInput}
																defaultValue={
																	formData[formData.extraRadios[radio].text]
																}
																customError={
																	customErrors[formData.extraRadios[radio].text]
																}
																required
															/>
														</React.Fragment>
													);
												})}
											<button
												className='btn3-secondary marginBottom-20'
												onClick={() => {
													addRadio();
												}}>
												Add radio
											</button>
											<CheckboxInput
												text='Make field required?'
												name={'required'}
												checked={formData?.required === true}
												callback={handleFormInput}
											/>
										</>
									);
								case 'header':
									return (
										<TextInput
											text={'Header text'}
											name={'header'}
											callback={handleFormInput}
											defaultValue={formData?.header}
											customError={customErrors['header']}
											required
										/>
									);
								case 'paragraph':
									return (
										<>
											{selected === 1 && (
												<TextareaInput
													text={'Paragraph body'}
													name={'paragraph'}
													callback={handleFormInput}
													defaultValue={formData?.paragraph}
													customError={customErrors['paragraph']}
													required
												/>
											)}
											{selected === 2 && (
												<CheckboxInput
													text='Include in docket PDF'
													name={'include'}
													checked={formData?.include === true}
													callback={handleFormInput}
												/>
											)}
										</>
									);
								case 'drawingTool':
									return (
										<>
											<TextInput
												text={'Label'}
												name={'fieldText'}
												callback={handleFormInput}
												defaultValue={formData?.fieldText}
												customError={customErrors['fieldText']}
												required
											/>
											<FileInput
												text={'Upload background image'}
												name={'drawToolImg'}
												btnText={'Choose image'}
												callback={handleFile}
												disabled={!formData?.fieldText}
											/>
											{formData.drawToolImg && (
												<div className='flexRow'>
													{formData.drawToolImg?.name}
													<TopnavCloseIcon
														iconClass='filePreviewIcon marginLeft-20'
														onClick={() => {
															handleFormInput(null, 'drawToolImg');
														}}
													/>
												</div>
											)}
											<CheckboxInput
												text='Make field required?'
												name={'required'}
												checked={formData?.required === true}
												callback={handleFormInput}
											/>
										</>
									);
								case 'graphic':
									return (
										<>
											<FileInput
												text={'Upload graphic'}
												name={'graphic'}
												btnText={'Choose image'}
												callback={handleFile}
											/>
											{formData?.graphic && (
												<div className='flexRow'>
													{formData?.graphic?.name}
													<TopnavCloseIcon
														iconClass='filePreviewIcon marginLeft-20'
														onClick={() => {
															handleFormInput(null, 'graphic');
														}}
													/>
												</div>
											)}
										</>
									);
								default:
									return <div>{field.name}</div>;
							}
						})()}
					</div>
				</div>
			)}
		</Draggable>
	);
}
